import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNormalProductsAsync,
  showNormalProducts,
} from "../../app/productSlice";

const Services = ({ bookAppointment }) => {
  const dispatch = useDispatch();
  const serviceList = useSelector(showNormalProducts);
  const [services, setServices] = useState([]);

  useEffect(() => {
    dispatch(getNormalProductsAsync());
  }, [dispatch]);

  useEffect(() => {
    if (serviceList) {
      setServices(serviceList);
    }
  }, [serviceList]);

  return (
    <div id="services" className="w-full flex flex-col items-center">
      <h2 className="text-white border-b-4 border-[#F8981D] bg-black px-2 py-1 font-black text-[16px] sm:text-[20px] leading-[23px] tracking-tighter mt-[68px]">
        MY SERVICES
      </h2>
      <div className="w-full px-[20px] lg:px-[80px] mx-auto flex flex-wrap justify-center gap-5 sm:gap-19 mt-[40px]">
        {services.map((service, ind) => {
          return (
            <article
              key={ind}
              className="w-full sm:w-[380px] bg-[#151515] hover:shadow-xl hover:cursor-pointer"
              onClick={() => bookAppointment(`${service.shortName}`)}
            >
              <img
                className="w-full h-auto"
                src={service.image}
                alt="service"
                loading="lazy"
              />
              <div className="w-full px-2 text-center">
                <h3 className="font-semibold text-[25px] leading-[29px] text-[#FFFFFF] mt-[30px]">
                  {service.name +
                    " $" +
                    (service.priceObj.unit_amount / 100).toFixed(2) +
                    (service.shortName === "SP" || service.isFullTimeService
                      ? "."
                      : "/Hr.")}
                </h3>
                <p className="text-[16px] leading-[19px] text-[#FFFFFF] mt-[17px] pb-[20px]">
                  {service.productObj.description}
                </p>
              </div>
            </article>
          );
        })}
      </div>
      <button
        className="w-[250px] mx-auto bg-black px-[32px] py-[15px] text-[#FFFFFF] font-bold mt-[53px] text-center border-4 border-[#F8981D] hover:bg-[#F8981D]"
        onClick={bookAppointment}
      >
        SCHEDULE A BOOKING
      </button>
    </div>
  );
};

export default Services;
